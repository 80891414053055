import React from 'react'
import PropTypes from 'prop-types'
import { CareerIconPropType } from '../propTypes'
import { getArrowForNumber } from '../helpers'

const NumbersArrows = ({ icons }) =>
  <div className='numbers-arrows'>
    {icons.map((icons, index) =>
      <div
        key={index}
        className='number-arrow'
      >
        <div>{index + 1}.</div>
        {getArrowForNumber(index)}
      </div>
    )}
  </div>

export default NumbersArrows

NumbersArrows.propTypes = {
  icons: PropTypes.arrayOf(CareerIconPropType)
}

NumbersArrows.defaultProps = {
  icons: []
}
