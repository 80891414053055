import React from 'react'
import PropTypes from 'prop-types'

const CareerTileText = ({ header, description }) =>
  <div className='career-tile-text'>
    <div>{header}</div>
    <div>{description}</div>
  </div>

export default CareerTileText

CareerTileText.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
}

CareerTileText.defaultProps = {
  header: '',
  description: '',
}
