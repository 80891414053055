import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'

const JobOffersButtonWithHeader = ({ header }) =>
  <SectionWrapper className='job-offers-button-with-header'>
    <div>
      {header}
    </div>
    <a target='_blank' href='https://senprima.join.com/' rel='noopener noreferrer'>
      Zu den Jobangeboten bei SenPrima
    </a>
  </SectionWrapper>

export default JobOffersButtonWithHeader

JobOffersButtonWithHeader.propTypes = {
  header: PropTypes.string.isRequired,
}
