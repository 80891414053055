import React from 'react'
import CareerTileText from './CareerTileText'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { IconWithTextPropType } from '../propTypes'

const CareerTile = ({ tiles, className }) =>
  <div className={className}>
    {tiles?.map((tile, index) =>
      <React.Fragment key={index}>
        {tile?.header
          ? <CareerTileText {...tile} />
          : <GatsbyImage
            className='career-tile-image'
            image={tile?.image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={tile?.image.alternativeText}
          />}
      </React.Fragment>
    )}
  </div>

export default CareerTile

CareerTile.propTypes = {
  tiles: PropTypes.arrayOf(IconWithTextPropType)
}

CareerTile.defaultProps = {
  tiles: []
}
