import React from 'react'
import CareerIcon from './CareerIcon'
import careerArrowImage from '../../images/careerIcons/arrowPointingRight.svg'
import PropTypes from 'prop-types'
import { CareerIconPropType } from '../propTypes'

const IconsNumbersArrows = ({ icons }) =>
  <div className='icons-numbers-arrows'>
    {icons.map((icon, index) =>
      <React.Fragment key={index}>
        <CareerIcon {...icon} index={index}/>
        {index < 3 &&
        <img
          className='career-icons-arrow'
          src={careerArrowImage}
          alt='Arrow icon'
        />}
      </React.Fragment>
    )}
  </div>

export default IconsNumbersArrows

IconsNumbersArrows.propTypes = {
  icons: PropTypes.arrayOf(CareerIconPropType)
}

IconsNumbersArrows.defaultProps = {
  icons: []
}
