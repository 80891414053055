import React from 'react'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'

const CareerIcon = ({ image, header, index }) =>
  <div className='career-icon'>
    <div className='career-icon__image'>
      <div className='career-icon__number'>{index + 1}.</div>
      <img
        width={image?.width}
        height={image?.height}
        src={image?.url}
        alt={image?.alternativeText}
      />
    </div>
    <div className='career-icon__header'>{header}</div>
  </div>

export default CareerIcon

CareerIcon.propTypes = {
  image: StrapiImagePropType,
  header: PropTypes.string,
  index: PropTypes.number
}

CareerIcon.defaultProps = {
  image: {},
  header: '',
  index: 1
}
