import React from 'react'
import Layout from '../components/Layout/Layout'
import CareerTiles from '../components/CareerTiles/CareerTiles'
import CareerIcons from '../components/CareerIcons/CareerIcons'
// import JobOffers from '../components/JobOffers/JobOffers'
import { graphql } from 'gatsby'
import JobOffersButtonWithHeader from '../components/JobOffers/JobOffersButtonWithHeader'

const CareerPage = ({ data }) => {
  const {
    allStrapiCareerPage: { edges: [{ node: { careerTiles, careerIcons, jobOffersHeader } }] },
    // allStrapiJobOffers: { edges }
  } = data

  // const jobOffersThumbnails = edges.map((edge) => ({
  //   ...edge.node.jobOffer,
  //   url: edge.node.url,
  //   id: edge.node.id
  // }))

  return <Layout>
    <CareerTiles {...careerTiles} />
    <CareerIcons {...careerIcons} />
    {/*<JobOffers*/}
    {/*  header={jobOffersHeader}*/}
    {/*  jobOfferThumbnails={jobOffersThumbnails}*/}
    {/*/>*/}
    <JobOffersButtonWithHeader header={jobOffersHeader} />
  </Layout>
}
export default CareerPage

export const query = graphql`
  query CareerPageQuery {
    allStrapiCareerPage {
      edges {
        node {
            ...careerTilesFragment
            ...careerIconsFragment
            jobOffersHeader
        }
      }
    }
  }
 `

//job offers if needed

// allStrapiJobOffers(sort: {order: DESC, fields: published_at}) {
//   edges {
//     node {
//     ...jobOfferThumbnailsFragment
//       url
//     }
//   }
// }
