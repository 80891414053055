import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import NumbersArrows from './NumbersArrows'
import IconsNumbersArrows from './IconsNumbersArrows'
import PropTypes from 'prop-types'
import { CareerIconPropType } from '../propTypes'
import { Controller, Scene } from 'react-scrollmagic'

const CareerIcons = ({ icons }) =>

  <SectionWrapper
    backgroundColorSm='white'
    backgroundColor='light-teal-gradient'
    className='career-icons'
  >
    <span className='career-icons__header'>
      <span>In nur </span>
      <span>4</span>
      <span> Schritten Mitarbeiter/-in bei SenPrima werden!</span>
    </span>
    <Controller>
      <Scene
        classToggle='career-icons-active'
        reverse={false}
        triggerHook='0.8'
      >
        <div className='career-icons__content'>
          <NumbersArrows icons={icons} />
          <IconsNumbersArrows icons={icons} />
        </div>
      </Scene>
    </Controller>
  </SectionWrapper>

export default CareerIcons

CareerIcons.propTypes = {
  icons: PropTypes.arrayOf(CareerIconPropType)
}

CareerIcons.defaultProps = {
  icons: []
}
